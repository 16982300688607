import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { SelectAccount } from '../../components'
import { SuggestTransferModalWrapper } from './styles/suggestTransferModal.styled'

import { GlobalContext, tr } from 'mmfintech-commons'
import { paths, useMerchantAccounts } from 'mmfintech-backend-api'

import { AccountBalanceOut } from 'mmfintech-commons-types'

type SuggestTransferModalProps = {
  account?: AccountBalanceOut
}

export const SuggestTransferModal = ({ account }: SuggestTransferModalProps) => {
  const { modalHide }: any = useContext(GlobalContext)

  const { activeAccounts } = useMerchantAccounts()

  const selectedAccount =
    account || (Array.isArray(activeAccounts) && activeAccounts.find((a: AccountBalanceOut) => a?.type === 'PRIMARY'))
  const history = useHistory()

  return selectedAccount ? (
    <SuggestTransferModalWrapper>
      <div className='suggest-text'>
        <div className='title'>{tr('FRONTEND.BANKING.SUGGEST_TRANSFER.TITLE', 'Insufficient balance')}</div>
        <div className='body'>
          {tr(
            'FRONTEND.BANKING.SUGGEST_TRANSFER.BODY',
            'You have insufficient balance in the selected currency account.'
          )}
        </div>
      </div>

      <div className='suggest-actions'>
        <SelectAccount
          staticDisplay
          selectedAccount={selectedAccount}
          label={tr('FRONTEND.BANKING.SUGGEST_TRANSFER.ACCOUNT', 'Account')}
        />
      </div>

      <div className='buttons-container'>
        <Button
          text={tr('FRONTEND.BANKING.SUGGEST_TRANSFER.BUTTON_TRANSFER', 'Transfer between accounts')}
          color='primary'
          onClick={() => {
            modalHide()
            history.push(paths.banking.sendIntraAccountTransfer())
          }}
        />
        <Button
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={() => {
            modalHide()
          }}
        />
      </div>
    </SuggestTransferModalWrapper>
  ) : null
}

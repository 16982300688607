import { tr } from 'mmfintech-commons'
import { useAccountCreateQry } from 'mmfintech-backend-api'

import { CurrencyIcon } from '../../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { AccountCreateForm, AccountCreateTitle, AccountCreateWrapper, LabelWrapper } from './AccountCreateModal.styled'

const Label = ({ currency, label }) => (
  <LabelWrapper>
    <CurrencyIcon currency={currency} size='2.6rem' />
    <span>{label}</span>
  </LabelWrapper>
)

export const AccountCreateModal = ({ filteredCurrencies = [], onSuccess }) => {
  const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreateQry({
    filteredCurrencies,
    onSuccess
  })

  const options = currencies?.map(({ value, label }) => {
    return {
      value: value,
      label: <Label currency={value} label={label} />
    }
  })

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title'>{tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'New account')}</AccountCreateTitle>

      <AccountCreateForm noValidate>
        <Input
          type='select'
          maxMenuHeight='25rem'
          data-test='currency'
          label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
          options={options}
          onChange={(name: string, value: string) => formValues.setValue(name, value)}
          {...formValues.registerShort('currency')}
          menuPosition='fixed'
        />

        <Input
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account name')}
          {...formValues.registerInput('name')}
        />

        <ErrorDisplay error={createAccountError} />

        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.ACCOUNTS.CREATE.SUBMIT_BUTTON', 'Add')}
          loading={createAccountFetching}
          data-test='button-submit'
          onClick={handleSubmit}
        />
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}

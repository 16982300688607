import { Line } from 'react-chartjs-2'
import { ChartWrapper } from './DashboardChart.styled'

import cn from 'classnames'
import { formatMoney, isValidArray } from 'mmfintech-commons'
import { selectMerchantCurrency, useAppSelector, useGraphQry } from 'mmfintech-backend-api'

export const DashboardChart = () => {
  const { chartData, chartLabels, isEmptyBalance } = useGraphQry()
  const merchantCurrency = useAppSelector(selectMerchantCurrency)

  const prepareData = () => ({
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        fill: true,
        backgroundColor: (context: any) => {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if (!chartArea) {
            return
          }
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
          gradient.addColorStop(0, 'rgba(255, 219, 30, 0.1)')
          gradient.addColorStop(1, 'rgba(255, 218, 30, 0)')
          return gradient
        },
        borderColor: '#FFCE32',
        borderWidth: 2,
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#FFCE32',
        pointBorderWidth: 3,
        pointRadius: 4,
        hoverBackgroundColor: '#ffffff',
        hoverBorderColor: '#ffce32',
        hoverBorderWidth: 2
      }
    ]
  })

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#b4bfcb',
          font: {
            family: 'Rubik',
            size: 12
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        max: isEmptyBalance() ? 5 : null,
        min: isEmptyBalance() ? 0 : null,
        ticks: {
          color: '#b4bfcb',
          font: {
            family: 'Rubik'
          },
          callback: function (value: number, index: number) {
            if (index === 0 || index % 2 === 0) {
              return formatMoney(value, merchantCurrency)
            }
          }
        },
        grid: {
          borderDash: [3, 4]
        }
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        position: 'average',
        caretPadding: 10,
        backgroundColor: '#ffffff',
        bodyColor: '#000000',
        padding: 15,
        bodyAlign: 'left',
        bodyFont: {
          family: 'inherit',
          weight: 400,
          size: 14
        },
        titleColor: '#a3b1bf',
        titleFont: {
          family: 'inherit',
          weight: 400,
          size: 10
        },
        callbacks: {
          title: () => merchantCurrency,
          label: (context: any) => {
            return formatMoney(context.raw, merchantCurrency)
          }
        }
      },
      legend: {
        display: false
      }
    }
  }

  return (
    <ChartWrapper className={cn({ loading: !isValidArray(chartData) })}>
      {isValidArray(chartData) ? (
        <>
          {/*@ts-ignore*/}
          <Line className='balance-chart' data={prepareData} type='line' options={options} data-test='balance-chart' />
        </>
      ) : null}
    </ChartWrapper>
  )
}

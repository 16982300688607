import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useContext, useState } from 'react'

import { fixFloat, getCurrencySymbol, GlobalContext, tr } from 'mmfintech-commons'
import {
  selectMerchantCurrency,
  useAppSelector,
  useMerchantAccounts,
  useSetExpenseLimitMutation
} from 'mmfintech-backend-api'
import { Button } from 'mmfintech-portal-commons'
import { InputAmount } from '../../components'

type ChangeLimitModalProps = {
  merchantUserId: number
  merchantUserName: string
  currency: string
  monthlyVolume: number
}

const ChangeLimitModal = ({ merchantUserId, merchantUserName, currency, monthlyVolume }: ChangeLimitModalProps) => {
  const { modalHide }: any = useContext(GlobalContext)

  const merchantCurrency = useAppSelector(selectMerchantCurrency)

  const { activeAccounts } = useMerchantAccounts()

  const [changeLimit] = useSetExpenseLimitMutation()
  const [updatedMonthlyVolume, setUpdatedMonthlyVolume] = useState(monthlyVolume)

  const onSuccess = () => {
    modalHide()
    toast.success(tr('FRONTEND.LIMITS.CHANGE_LIMIT.SUCCESS_TOAST', 'Limit successfully updated'))
  }

  const handleSubmit = async () => {
    if (updatedMonthlyVolume) {
      try {
        await changeLimit({
          monthlyVolume: fixFloat(updatedMonthlyVolume),
          merchantUserId
        }).unwrap()
        onSuccess()
      } catch (error) {}
    }
  }

  return (
    <ChangeLimitModalWrapper data-test='change-limit-modal'>
      <ChangeLimitInner>
        <div className='title'>{tr('FRONTEND.LIMITS.CHANGE_LIMIT.TITLE', 'Change limit')}</div>
        <div className='change-limit-text'>
          {tr('FRONTEND.LIMITS.CHANGE_LIMIT.BODY', 'Change the monthly limit of user:')} <span>{merchantUserName}</span>
        </div>

        <InputAmount
          label={tr('FRONTEND.LIMITS.CHANGE_LIMIT.AMOUNT', 'Set monthly limit')}
          placeholder={getCurrencySymbol(currency)}
          amount={updatedMonthlyVolume.toString()}
          setAmount={value => setUpdatedMonthlyVolume(parseFloat(value))}
          account={activeAccounts?.find((acc: any) => acc.currencyCode === merchantCurrency && acc.type === 'PRIMARY')}
        />

        <Button
          text={tr('FRONTEND.LIMITS.CHANGE_LIMIT.BUTTON_SET', 'Set')}
          disabled={!updatedMonthlyVolume}
          color='primary'
          onClick={handleSubmit}
        />
        <Button text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')} color='secondary' onClick={modalHide} />
      </ChangeLimitInner>
    </ChangeLimitModalWrapper>
  )
}

export default ChangeLimitModal

const ChangeLimitInner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  .title {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;

    margin: 2rem 0;
  }
  .change-limit-text {
    color: #333333;

    font-size: 1.5rem;
    margin: 0 0 4rem 0;
    span {
      color: #000000;
      font-weight: 500;
    }
  }
  .button {
    align-self: center;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
`

const ChangeLimitModalWrapper = styled.div`
  color: #000000;
  width: 100%;
  min-width: 50rem;
  @media (max-width: 565px) {
    max-width: 35rem;
    min-width: 35rem;
    padding: 1rem;
  }

  padding: 4rem 5rem;
`
